/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background: $section-bg-color;
    position: relative;
    font-size: 15px;
    overflow: hidden;

    ul {
        list-style: none;
    }

    p{
        color: #525252;
    }
    li{
        color:#525252;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer{
        padding: 100px 120px;
        position: relative;
        z-index: 1;

        @media(max-width:1800px){
            padding: 80px 50px;
        }

        &:before{
            position: absolute;
            left: 1%;
            top: 4%;
            border: 1px solid #cde0ef;
            content: "";
            width: 98%;
            height: 92%;
            z-index: -1;

            @media(max-width:1200px){
                height: 96%;
                top: 2%;
            }

            @media(max-width:991px){
               display: none;
            }
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer{
            padding:60px 30px 0;
        }
    }
    
    @media (max-width: 767px) {
        .wpo-upper-footer{
            padding:30px 20px 0;
        }
    }
    
    
    @media (max-width: 991px) {
    
        .wpo-upper-footer .col{
            min-height: 235px;
            margin-bottom: 70px;
        }
    }
    
    @media (max-width: 767px) {
    
        .wpo-upper-footer .col{
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title{
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title{
            margin-bottom: 20px;
        }
    }


    .widget-title{
        h3{
            font-size: 20px;
            color: $dark-gray;
            margin: 0;
            text-transform: uppercase;
            position: relative;
            font-family: $heading-font;
            font-weight: 600;

            @media (max-width: 991px) {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }
    
    .about-widget {
        .logo {
            max-width: 180px;
            position: relative;
            left: -55px;
            @media(max-width:991px){
                left: -20px;
            }

        }
    }

    .about-widget {
        padding-right: 45px;
        padding-left: 55px;

        @media(max-width:1500px){
            padding-right: 15px;
            padding-left: 15px;
        }
        p{
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child{
                margin-bottom: 0;
            }
        }

        ul{
            overflow: hidden;
            padding-top: 10px;

            li{
                font-size: 22px;
                float: left;

                a{
                    color: $dark-gray;
                    width: 36px;
                    height: 36px;
                    line-height: 40px;
                    background: $white;
                    display: block;
                    text-align: center;
                    @include rounded-border(50%);
                    font-size: 18px;

                    &:hover{
                        background:$dark-gray;
                        color: $white;
                    }
                }
            }

            li+li{
                margin-left: 25px;

                @media screen and (max-width: 1600px) {
                    margin-left: 10px;
                }

            }
        }
    }

        
    .link-widget {
        overflow: hidden;
        

        @media screen and (min-width: 1500px) {
            padding-left: 75px;
        }
        
        @media (max-width: 1199px) {
            padding-left: 20px;
        }
        
        @media (max-width: 991px) {
            padding-left: 0;
        }
        
        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul{
            li{
                position: relative;
                a{
                    color:  #575757;
                    font-size: 17px;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            li+li{
              padding-top: 15px;
            }
        }
    }


    .contact-ft {
        margin-top: 20px;
        padding-right: 60px;

        @media(max-width:1500px){
            padding-right:0px;
        }

        ul {
            li {
                padding-bottom: 15px;
                position: relative;
                padding-left: 35px;
                color: #5880a2;
                font-size: 16px;

                i{
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .fi:before{
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
    }
    
    

    .wpo-lower-footer{
        text-align: center;
        position: relative;
        background: $white;
    
        .row{
            padding: 20px 0;
            position: relative;
        }
    
        .copyright{
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
            color: #5880a2;
    
            a{
                color: #5880a2;
                text-decoration: none;
            }
    
            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }
    
    .instagram{
        @media(max-width:1200px){
            margin-top: 40px;
        }
        @media(max-width:991px){
            margin-top: 0px;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;
    
            li{
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;
    
                img{
                    width: 100%;
                    height: 90px;
                    object-fit: cover;
                }
            }
        }
    }
}






