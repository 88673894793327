/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Andreea and Henry - Wedding & Wedding Planner HTML5 Template
Version:        1.0.0
-------------------------------------------------------------------*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

1. General
	1.1 Theme Reset Style
	1.2 Global Elements

2. header
	2.1 topbar
	2.2 navigation

3. content
	3.1 wpo-hero-slider
	3.2 wpo-partners-section
	3.3 wpo-service-section
	3.4 wpo-video-section
	3.5 wpo-fun-fact-section
	3.6 wpo-portfolio-section
	3.7 wpo-testimonials-section
	3.8 wpo-product-section
	3.9 wpo-cta-section
	3.10 wpo-pricing-section
	3.11 wpo-banner-section
	3.12 wpo-contact-section
	3.13 wpo-blog-section

4. wpo-footer

5. Home-style-2
   5.1 wpo-about-section
   5.2 wpo-service-section-s2
   5.3 wpo-fun-fact-section-s2
   5.4 wpo-team-section
   5.5 wpo-cta-section-s2
   5.6 wpo-blog-section-s2
   5.7 wpo-partners-section-s2

 6. Home-style-3 
  6.1 wpo-wedding-date
	6.2 couple-section
	6.3 wpo-video-section-s2
	6.4 story-section
	6.5 wpo-portfolio-section-s2
	6.6 wpo-cta-section-s3
	6.7 wpo-event-section
	6.8 wpo-partners-section-s3
	6.9 wpo-blog-section-s2

 7. Home-style-4
    7.1 wpo-portfolio-section-s3

 8. Home-style-7
	8.1 wpo-product-section-s2
	8.2 wpo-category-section
	8.3 wpo-product-section-s3
	8.4 wpo-offer-banner-section

9. wpo-about-section

10. wpo-service-single

11. coming-soon-section

12. wpo-cart-page-style

13. wpo-checkout-page-style

14. wpo-shop-page

15. wpo-shop-single-page

16. wpo-team-single

17. wpo-portfolio-single-section

18. wpo-blog-pg-section

19. wpo-blog-single-section

20. wpo-contact-pg-section

21. error-404-section

----------------------------------------------------------------*/


/*------------------------------------------------------------------
1. General
----------------------------------------------------------------*/

// helpers
@import "helpers/variables";
@import "helpers/functions";
@import "helpers/mixins";

// base
@import "base/base";


// components
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/preloader";
@import "components/blog-sidebar";
@import "components/shop-sidebar";
@import "components/pagination";

// layout
@import "layout/header";
@import "layout/hero-slider";
@import "layout/footer";

// components
@import "components/sticky-header";


// page
@import "page/home-default";

@import "page/_home-style2";

@import "page/_home-style3";

@import "page/_home-style4";

@import "page/_home-style7";


@import "page/_about";

@import "page/_service";

@import "page/_coming-soon";

@import "page/_cart";

@import "page/_checkout";

@import "page/_shop";

@import "page/_shop-single";

@import "page/_team-single";

@import "page/_portfolio-single";

@import "page/_blog";

@import "page/_blog-single";

@import "page/_contact";

@import "page/_error-404";



