/*--------------------------------------------------------------
11. coming-soon-section
--------------------------------------------------------------*/

.coming-soon-section{
    .wpo-wedding-date{
        padding: 30px 0 60px;
        @include media-query(767px) {
            padding: 0px 0 15px;
        }
    }


    .coming-soon-text{
        text-align: center;
        h2{
            font-size: 60px;

            @include media-query(767px) {
                font-size: 30px;
            }
        }
    }

    .wpo-coming-contact{
        .form-control{
          width: 100%;
          height: 55px;
          background: none;
          border: 1px solid #ccc;
          margin-bottom: 20px;

          &:focus{
              outline: none;
              box-shadow: none;
          }
        }

        button{
            height: 55px;
            background: $theme-primary-color-s2;
            border: none;
            color: $white;
            width: 100%;
        }
    }
}